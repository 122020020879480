import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import * as layoutStyles from "../styles/components/RoundImage.module.scss";

const RoundImage = ({ fixed, alt }) => {
  return (
    <div>
    <GatsbyImage 
      className={layoutStyles.roundImage} 
      image={fixed} 
      alt={alt} 
    />
    </div>
  );
};

export default RoundImage;
