import React, { useContext } from 'react';
import classNames from 'classnames';
import { FaChevronRight, FaChevronDown } from 'react-icons/fa';

import { ThemeContext } from './ThemeProvider';
import * as accordionStyles from '../styles/components/Accordion.module.scss';

type AccordionSectionProps = {
  isOpen: boolean, 
  id: string,
  label: string,
  onClick: (label: string) => void,
}

type DivStyles = React.CSSProperties;

const AccordionSection : React.FC<AccordionSectionProps> = (props) => {
  const { isOpen, id, label, onClick, children } = props;
  const { isDarkMode } = useContext(ThemeContext);

  const sectionStyles : DivStyles = isOpen ? { display: 'block' } : {};

  const accordionHeaderClass = classNames({
    [accordionStyles.accordionHeader]: true,
    [accordionStyles.darkTheme]: isDarkMode
  });

  const contentClass = classNames({
    [accordionStyles.content]: true,
    [accordionStyles.darkTheme]: isDarkMode
  });

  return (
    <div
        style={sectionStyles}
    >
      <div 
        className={accordionHeaderClass}
        onClick={() => onClick(id)}
      >
        { label }
        <div style={{ float: "right" }}>
          {!isOpen && <FaChevronRight size={18}/>}
          {isOpen && <FaChevronDown size={18} />}
        </div>
      </div>
    {isOpen && (
      <div className={contentClass}>
        {children} 
      </div>
    )}
    </div>
  );
}

export default AccordionSection;
