import React from 'react';
import { Link, graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Head from '../components/Head';
import Layout from '../components/Layout';
import RoundImage from '../components/RoundImage';
import PersonalProjects from '../components/PersonalProjects.tsx';

import * as layoutStyles from '../styles/pages/About.module.scss';

const AboutPage = ({ data }) => {

  const { t } = useTranslation("about");

  return (
      <Layout>
        <Head title="About" />
        <div className={layoutStyles.profileContainer} >
          <div className={layoutStyles.profilePicture}>
            <RoundImage 
              fixed={data.file.childImageSharp.gatsbyImageData} 
              alt="my photo" 
            />
          </div>
          <div className={layoutStyles.profileDescription}>
            <h1>
              {t("About me")}
            </h1>
            <p>
              {t('my-description')}
            </p>
            <p>{t('need-developer')} <Link to="/contact">{t('contact-me')}</Link></p>
          </div>
        </div>
        <PersonalProjects />
      </Layout>
  );
}

export default AboutPage;


export const query = graphql`
  query($language: String!) {
    file(relativePath: { eq: "deepart.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED,
          width: 150,
          height: 150
        )
      }
    }
    locales: allLocale(filter: {ns: {in: ["about", "projects", "header"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`